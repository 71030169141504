import { ComponentProps, FC } from 'react';
import styles from './UserMenu.module.scss';
import { FaroAvatar, FaroIconWithExpandableContent, FaroMenu, FaroOverlayTrigger } from '@faro/design-system';
import { LogoutIcon, MoreHorizontalIcon, ChartLineBarIcon } from '@faro/icons';
import { useAuth } from '@faro/auth';
import { AnalyticsEventKeys, createUserDisplayName, ProductTypes, useAnalytics } from '@faro/app-context';

export interface UserMenuProps extends ComponentProps<'button'> {
    /**
     * User information from auth
     **/
    user: MenuUser;

    /**
     * If true, component shows avatar, user name and role. If false, component only shows avatar .
     * Default: true
     **/
    isExpanded: boolean;
}

export interface MenuUser {
    firstName?: string;
    lastName?: string;
    email: string;
    roles: string[];
}

const UserMenu: FC<UserMenuProps> = (props): JSX.Element | null => {
    const { user, isExpanded = true, className = '', ...rest } = props;
    const auth = useAuth();
    const { trackEvent } = useAnalytics();

    if (user == null) return null;

    const onSystemStatusButtonClick = (): void => {
        trackEvent(AnalyticsEventKeys.clickSystemStatusLink, { product: ProductTypes.studySpace });
        window.open('https://farohealth.statuspage.io', '_blank');
    };

    async function onLogoutButtonClick(): Promise<void> {
        trackEvent(AnalyticsEventKeys.logout, { product: ProductTypes.studySpace });
        await auth?.logout();
    }

    const displayName = createUserDisplayName(user);
    return (
        <div className={styles.menuContainer} data-automation-id="userMenu">
            <FaroOverlayTrigger
                openOnClick
                placement="right-end"
                overlay={
                    <FaroMenu>
                        <FaroMenu.Item
                            left={<ChartLineBarIcon />}
                            onClick={onSystemStatusButtonClick}
                            data-automation-id="systemStatusButton"
                            data-intercom-target="SystemStatus"
                        >
                            System status
                        </FaroMenu.Item>
                        <FaroMenu.Item
                            left={<LogoutIcon />}
                            onClick={onLogoutButtonClick}
                            data-automation-id="logoutButton"
                            data-intercom-target="Logout"
                        >
                            Log out
                        </FaroMenu.Item>
                    </FaroMenu>
                }
            >
                <button className={`${styles.unstyledButtonContainer} ${className}`} {...rest}>
                    <FaroIconWithExpandableContent
                        icon={
                            <FaroAvatar
                                user={user}
                                className={styles.avatar}
                                showTooltip={false}
                                data-automation-id="avatar"
                            />
                        }
                        expandContent={
                            <div className={styles.expandContentContainer}>
                                <div className={styles.userInfo} data-automation-id="userDisplayName">
                                    <div className={styles.nameText}>{displayName}</div>
                                </div>
                                <div className={styles.rightIcon} data-automation-id="rightIcon">
                                    <MoreHorizontalIcon />
                                </div>
                            </div>
                        }
                        isExpanded={isExpanded}
                        className={styles.iconWithExpandableContentContainer}
                    />
                </button>
            </FaroOverlayTrigger>
        </div>
    );
};

export default UserMenu;
