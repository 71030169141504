import { AppInsightsConnector as Connector } from '@faro/app-insights';
import { useSystemConfigQuery } from '../../query';

export const AppInsightsConnector = (): JSX.Element | null => {
    const { data: systemConfig } = useSystemConfigQuery();

    if (!systemConfig || !systemConfig.appInsightsClientConfig) {
        return null;
    }

    return (
        <Connector
            enabled={systemConfig.appInsightsClientConfig.enabled}
            clientConfig={{
                connectionString: systemConfig.appInsightsClientConfig.connectionString,
            }}
        />
    );
};
