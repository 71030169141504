import { useEffect } from 'react';
import { useUser } from '@faro/auth';
import { useSystemConfigQuery } from '../../query';
import { useScript } from './useScript';
import { isBlank } from '../../util/isBlank';
import { UserInfo } from '@faro/user-management-model';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios from 'axios';
import { useBeforeunload } from 'react-beforeunload';
import { IntercomUserOptions } from '@faro/study-designer-model';
import { isEmpty } from 'lodash';
import { createUserDisplayName } from '@faro/app-context';

function closeIntercomSession(): void {
    if (typeof Intercom === 'function') {
        Intercom('hide');
        Intercom('shutdown');
    }
}

/**
 * Injects and configures Intercom chat client into the UI.
 *
 * Additional resources for configuring the intercom chat bubble:
 *
 * Via admin ui:
 *  1. https://www.intercom.com/help/en/articles/178-customize-the-intercom-messenger-basics
 *  2. https://www.intercom.com/help/en/articles/189-turn-off-show-or-hide-the-intercom-messenger
 *
 * Via code:
 *  1. https://developers.intercom.com/installing-intercom/docs/intercom-javascript
 *  2. https://www.intercom.com/help/en/articles/2894-customize-the-intercom-messenger-technical
 */
function ChatBot(): null {
    const user = useUser<UserInfo>();
    const { data: systemConfig } = useSystemConfigQuery();
    const intercomApplicationId = systemConfig?.intercom?.applicationid;
    const intercomEnabled = !isBlank(intercomApplicationId);

    const { loaded: scriptLoaded } = useScript({
        src: `https://widget.intercom.io/widget/${intercomApplicationId}`,
        enabled: intercomEnabled,
    });

    const { data: intercomUserData } = useIntercomUserData({
        enabled: intercomEnabled && !isEmpty(user?.roles),
    });

    useBeforeunload(() => {
        closeIntercomSession();
    });

    useEffect(() => {
        if (!scriptLoaded || user?.id == null || intercomUserData == null) {
            return;
        }

        const displayName = createUserDisplayName(user);

        const intercomBootOptions = {
            app_id: intercomApplicationId,
            name: `${displayName}`,
            email: user.email,
            user_id: user.id,
            user_hash: intercomUserData.hash,
        };

        Intercom('boot', intercomBootOptions);

        return () => {
            closeIntercomSession();
        };
    }, [scriptLoaded, user?.id, intercomUserData?.hash]);

    return null;
}

function useIntercomUserData(options: { enabled: boolean }): UseQueryResult<IntercomUserOptions> {
    return useQuery<IntercomUserOptions>({
        queryKey: ['intercom-user-data'],
        queryFn: async () =>
            axios.get<IntercomUserOptions>('/api/study-designer/intercom/user-options').then(x => x.data),
        staleTime: Infinity,
        refetchIntervalInBackground: false,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        refetchOnMount: false,
        enabled: options.enabled,
    });
}

export default ChatBot;
