import { useUser } from '@faro/auth';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { cacheForeverQueryOptions } from '../package/react-query';
import { SystemConfig } from '@faro/study-designer-model';
import { useServiceContext } from '../service';
import { isEmpty } from 'lodash';

export function useSystemConfigQuery(): UseQueryResult<SystemConfig> {
    const { systemConfigService } = useServiceContext();
    const user = useUser();

    return useQuery(['systemConfig'], () => systemConfigService.get(), {
        ...cacheForeverQueryOptions,
        enabled: !isEmpty(user?.roles),
    });
}
