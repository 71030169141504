import { FaroAlert } from '@faro/design-system';
import React, { ErrorInfo, ReactNode } from 'react';
import { Link } from 'react-router-dom';

interface ErrorBoundaryProps {
    children: ReactNode | ReactNode[];
    showLink?: boolean;
    link?: string;
    linkText?: string;
    onLinkClick?: () => any;

    onUnhandledRejection?(event: PromiseRejectionEvent): void;
}

interface ErrorBoundaryState {
    hasError: boolean;
    error?: unknown;
}

/**
 * Simple ErrorBoundary protecting auth flow from uncaught rendering errors.
 */
export class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
    state = {
        hasError: false,
    };

    constructor(props: ErrorBoundaryProps) {
        super(props);
    }

    static getDerivedStateFromError(error: any): ErrorBoundaryState {
        // Update state so the next render will show the fallback UI.
        return { hasError: true, error };
    }

    private onUnhandledRejection(event: PromiseRejectionEvent): void {
        this.props.onUnhandledRejection?.(event);
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
        console.error(`Uncaught error during rendering:\n${errorInfo.componentStack}`, error);
    }

    componentDidMount(): void {
        // Add an event listener to the window to catch unhandled promise rejections & stash the error in the state
        window.addEventListener('unhandledrejection', event => this.onUnhandledRejection(event));
    }

    componentWillUnmount(): void {
        window.removeEventListener('unhandledrejection', event => this.onUnhandledRejection(event));
    }

    render(): ReactNode {
        const { showLink = false, link = '/', linkText = 'Home', onLinkClick = () => {}, children } = this.props;
        const { hasError } = this.state;
        const onLinkClickCallback = () => {
            onLinkClick();
        };
        if (hasError) {
            // You can render any custom fallback UI
            return (
                <FaroAlert type="error">
                    <FaroAlert.Header>Unrecoverable Error</FaroAlert.Header>
                    <FaroAlert.Body>
                        One or more components have thrown an unrecoverable, uncaught error. Please contact support.
                        {showLink && (
                            <Link to={link} onClick={onLinkClickCallback}>
                                {' ' + linkText}
                            </Link>
                        )}
                    </FaroAlert.Body>
                </FaroAlert>
            );
        }

        return children;
    }
}
