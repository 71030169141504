import React from 'react';
import { useFeatureFlagsQuery } from '../../query';
import ErrorMessage from '../../component/ErrorMessage/ErrorMessage';
import { AuthenticationSpinner } from '../../package/auth';

function FeatureFlagsInitializer({ children }: { children: React.ReactNode }): React.ReactNode {
    const { isFetched: featureFlagsFetched, error: featureFlagQueryError } = useFeatureFlagsQuery();
    if (!featureFlagsFetched) {
        return <AuthenticationSpinner />;
    }

    if (featureFlagQueryError != null) {
        return <ErrorMessage error={featureFlagQueryError as Error} message="Failed to load application settings" />;
    }
    return <>{children}</>;
}

export default FeatureFlagsInitializer;
