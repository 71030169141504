import React, { PropsWithChildren } from 'react';
import { MutationCache, QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useAppContext } from '@faro/app-context';

export function AppQueryClientProvider(props: PropsWithChildren<unknown>): JSX.Element {
    const { showErrorNotification } = useAppContext();

    const queryClient = new QueryClient({
        queryCache: new QueryCache({
            onError: error => {
                showErrorNotification('Server communication failure', error);
            },
        }),
        mutationCache: new MutationCache({
            onError: error => {
                showErrorNotification('An error occurred saving your data', error);
            },
        }),
    });

    return <QueryClientProvider client={queryClient}>{props.children}</QueryClientProvider>;
}
