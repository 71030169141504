import { SystemConfig } from '@faro/study-designer-model';
import { systemConfigClient } from './SystemConfigClient';

/**
 * Service responsible for working with {@link SystemConfig}.
 */
export class SystemConfigService {
    readonly get: () => Promise<SystemConfig>;

    constructor() {
        this.get = systemConfigClient.get;
    }
}
