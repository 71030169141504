import { PropsWithChildren } from 'react';
import { AuthorizedRoute } from '@faro/auth';
import { AppName, AppPermissions } from './AppPermissions';
import { useAppPermissions } from './useAppPermissions';
import ErrorMessage from '../../component/ErrorMessage/ErrorMessage';

export interface AppRootAuthorizedRouteProps extends PropsWithChildren<any> {
    appName: AppName;
}

export function AppRootAuthorizedRoute(props: AppRootAuthorizedRouteProps): React.ReactNode {
    const { appName, children } = props;

    return (
        <AuthorizedRoute<AppPermissions>
            granted={x => x.grantedApps.has(appName)}
            useAppPermissions={useAppPermissions}
            errorHandler={(error: unknown, message: string) => <ErrorMessage error={error} message={message} />}
        >
            {children}
        </AuthorizedRoute>
    );
}
