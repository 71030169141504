import React from 'react';
import { useAppPermissions } from '../../package/auth';
import { Link } from 'react-router-dom';
import styles from './NotFound.module.scss';
import pageNotFoundSvg from './page-not-found.min.svg';

export function NotFound(): JSX.Element {
    const { grantedApps } = useAppPermissions();
    const hasStudySpacesAccess = grantedApps.has('study-spaces');

    return (
        <div className={styles.root}>
            <img className={styles.image} src={pageNotFoundSvg} alt="This page is unavailable" />
            <span className={styles.text}>This page is unavailable</span>
            {hasStudySpacesAccess && (
                <div className={styles.links}>
                    <div className={styles.divider}></div>
                    <div className={styles.navigateText}>
                        Try a different destination or return to <Link to={'/spaces'}>Study Spaces</Link>.
                    </div>
                </div>
            )}
        </div>
    );
}
