/**
 * Common react-query {@link UseQueryOptions} that indicate a query should be fetched once
 * and cached indefinitely or at least until the page is refreshed.
 */
export const cacheForeverQueryOptions = {
    staleTime: Infinity,
    refetchIntervalInBackground: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchOnMount: false,
};
