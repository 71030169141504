import styles from './SideNav.module.scss';
import { ComponentPropsWithRef, forwardRef, useState } from 'react';
import { FaroPersistentDrawer, FaroScrollFog, useScrollStateListener } from '@faro/design-system';
import SideNavHeader, { LinkData } from './SideNavHeader/SideNavHeader';
import UserMenu, { MenuUser } from './UserMenu/UserMenu';
import { useAppNavigation, useLocalStorageState } from '@faro/app-context';
import { useRoutes } from 'react-router-dom';
import sideNavWidgetRoutes from './routes';

export interface SideNavProps extends ComponentPropsWithRef<'div'> {
    user: MenuUser;
    headerLinks?: LinkData[];
    headerClass?: string;
}

const SideNav = forwardRef<HTMLDivElement, SideNavProps>((props, ref) => {
    const { children, headerLinks, headerClass, className = '', user, ...rest } = props;

    const content = useAppNavigation();
    const routes = useRoutes(sideNavWidgetRoutes);

    const [open, setOpen] = useLocalStorageState('faro.side-nav.open', true);
    const [size, setSize] = useLocalStorageState<number | null>('faro.side-nav.size', null);

    const [scrollContainerElement, setScrollContainerElement] = useState<HTMLElement | null>();
    const [scrollElement, setScrollElement] = useState<HTMLElement | null>();

    useScrollStateListener(scrollElement, (scrollState: any) => {
        scrollContainerElement?.style.setProperty(
            `--faro-scroll-fog-opacity-top`,
            String(scrollState.canScrollUp ? 1 : 0)
        );
        scrollContainerElement?.style.setProperty(
            `--faro-scroll-fog-opacity-bottom`,
            String(scrollState.canScrollDown ? 1 : 0)
        );
    });

    return (
        <div {...rest} ref={ref} className={`${className} ${styles.sideNav}`}>
            <FaroPersistentDrawer
                resizable
                size={size || undefined}
                onSizeChange={value => {
                    setSize(value || null);
                }}
                snapOpenOrCloseWhenResizeUnder={250}
                className={styles.drawer}
                placement="left"
                elevation="lowered"
                open={open}
                onOpenChange={setOpen}
                data-automation-id="sideNav"
            >
                <div className={`${styles.sideNavContent} ${open ? styles.open : ''}`}>
                    <SideNavHeader className={headerClass} links={headerLinks} open={open} />
                    <div className={styles.main} ref={setScrollContainerElement}>
                        <div className={styles.scrollContainer} ref={setScrollElement}>
                            {routes}
                            {children}
                            {content}
                            <FaroScrollFog className={styles.scrollFog} />
                        </div>
                    </div>
                    <UserMenu user={user} isExpanded={true} />
                </div>
            </FaroPersistentDrawer>
        </div>
    );
});

export default SideNav;
