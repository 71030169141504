import { toUserInfo, UserInfo } from '@faro/user-management-model';
import axios from 'axios';

export interface UserInfoQuery {
    /**
     * The user's internal IDs comma separated string.
     */
    readonly ids?: string;

    /**
     * The user's idp IDs comma separated string.
     */
    readonly idpIds?: string;
}

/**
 * Implementations of this interface are responsible for fetching the
 * current user info.
 */
export interface UserInfoService {
    /**
     * Retrieve the current user info
     */
    getCurrent(): Promise<UserInfo>;

    /**
     * Get all users' info for a tenant.  The tenant value comes from the logged-in user's user token.
     * @param userInfoQuery The user info query
     */
    getAll(userInfoQuery?: UserInfoQuery): Promise<UserInfo[]>;
}

export class DefaultUserInfoService implements UserInfoService {
    getCurrent(): Promise<UserInfo> {
        return axios.get('/api/user-management/users/user-info/current').then(response => toUserInfo(response.data));
    }

    getAll(userInfoQuery?: UserInfoQuery): Promise<UserInfo[]> {
        return axios
            .get('/api/user-management/users/user-info', { params: userInfoQuery })
            .then(response => response.data);
    }
}
