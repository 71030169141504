import { FeatureFlag } from '@faro/study-designer-model';
import { featureFlagClient } from './FeatureFlagClient';
import { Dictionary, keyBy } from 'lodash';

/**
 * Service responsible for working with {@link FeatureFlag}s.
 */
export class FeatureFlagService {
    private featureFlags?: FeatureFlag[];
    private flags?: Dictionary<FeatureFlag>;

    constructor() {}

    /**
     * Loads all feature flags in the system.
     */
    async loadFeatureFlags(): Promise<FeatureFlag[]> {
        if (this.featureFlags == null) {
            this.featureFlags = await featureFlagClient.list();
        }
        if (this.flags == null) {
            this.flags = keyBy(this.featureFlags!, x => x.id);
        }
        return this.featureFlags!;
    }

    /**
     * Returns whether the feature flag is enabled.  If the service has not be initialized via the init()
     * function the {@code false} will always be returned.  If the flag cannot be found {@code false}
     *
     * @param featureFlagName the {@link FeatureFlagName} to check if enabled
     */
    enabled(featureFlagName: FeatureFlagName): boolean {
        if (this.flags == null) {
            throw new Error(
                `FeatureFlagService.loadFeatureFlags() must be called before calling FeatureFlagService.enabled()`
            );
        }
        return this.flags[featureFlagName]?.enabled || false;
    }
}

/**
 * Feature flag name definitions.
 * NOTE: names must be lower-cased
 */
export type FeatureFlagName =
    | 'editsystemdynamicfielddefinitions'
    | 'hideconnectionlostmessage'
    | 'analytics'
    | 'intercominsightshelp';
