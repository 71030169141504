import React, { Suspense } from 'react';
import { NotFound } from './page/NotFound/NotFound';
import { AuthenticationSpinner, AuthProvider, SignedOffMessage } from './package/auth';
import ChatBot from './package/intercom/ChatBot';
import {
    AppNavigationContextProvider,
    AppOutlet,
    AppRouteObject,
    PageContextProvider,
    PageOverlayProvider,
    UnsavedChangesContextProvider,
} from '@faro/app-context';
import SessionExpiredMessage from './package/auth/SessionExpiredMessage/SessionExpiredMessage';
import { AppInsightsConnector } from './component/AppInsightsConnector/AppInsightsConnector';
import { NavLayout } from './component/NavLayout/NavLayout';
import { ErrorBoundary } from './component/ErrorBoundary/ErrorBoundary';
import ScrollToTop from './package/react-router-dom/ScrollToTop';
import Home from './page/Home';
import { AppAnalyticsProvider } from './package/analytics/AppAnalyticsProvider';
import FeatureFlagsInitializer from './component/FeatureFlagsInitializer/FeatureFlagsInitializer';
import { AuthenticatedRoute } from '@faro/auth';
import ErrorMessage from './component/ErrorMessage/ErrorMessage';
import { AppRootAuthorizedRoute } from './package/auth/AppRootAuthorizedRoute';

// typecast to any here because package.json exports d.ts paths are not resolved by typescript...
const SpacesModule = React.lazy(() => import('@faro/study-space-ui' as any));
const ContentLibraryModule = React.lazy(() => import('@faro/content-library-ui' as any));
const UserManagementModule = React.lazy(() => import('@faro/user-management-ui' as any));
const StudyDesignerModule = React.lazy(() => import('@faro/study-designer-ui' as any));

const rootOutletContext = { depth: 0 };

const routes: AppRouteObject[] = [
    {
        path: '/',
        element: (
            <ErrorBoundary>
                <AuthProvider>
                    <AppAnalyticsProvider>
                        <PageContextProvider>
                            <PageOverlayProvider>
                                <AppNavigationContextProvider defaultApplicationTitle="Faro">
                                    <UnsavedChangesContextProvider>
                                        <ScrollToTop />
                                        <AppOutlet context={rootOutletContext} />
                                    </UnsavedChangesContextProvider>
                                </AppNavigationContextProvider>
                            </PageOverlayProvider>
                        </PageContextProvider>

                        <ChatBot />
                        <AppInsightsConnector />
                    </AppAnalyticsProvider>
                </AuthProvider>
            </ErrorBoundary>
        ),
        children: [
            {
                path: '/',
                element: (
                    <FeatureFlagsInitializer>
                        <AuthenticatedRoute
                            loading={<AuthenticationSpinner />}
                            errorHandler={(error, message) => <ErrorMessage error={error} message={message} />}
                        >
                            <NavLayout>
                                <AppOutlet />
                            </NavLayout>
                        </AuthenticatedRoute>
                    </FeatureFlagsInitializer>
                ),
                children: [
                    {
                        index: true,
                        element: <Home />,
                    },
                    {
                        // Needed to support auth flow
                        path: 'auth/openid/return',
                        element: <AuthenticationSpinner />,
                    },
                    {
                        path: '/spaces/*',
                        breadcrumb: {
                            name: 'Spaces',
                        },
                        element: (
                            <Suspense fallback={null}>
                                <AppRootAuthorizedRoute appName="study-spaces">
                                    <SpacesModule />
                                </AppRootAuthorizedRoute>
                            </Suspense>
                        ),
                    } as AppRouteObject,
                    {
                        path: '/studies/*',
                        breadcrumb: {
                            name: 'Study Designer',
                        },
                        element: (
                            <Suspense fallback={null}>
                                <AppRootAuthorizedRoute appName="study-designer">
                                    <StudyDesignerModule />
                                </AppRootAuthorizedRoute>
                            </Suspense>
                        ),
                    } as AppRouteObject,
                    {
                        path: '/libraries/*',
                        breadcrumb: {
                            name: 'Library',
                        },
                        element: (
                            <Suspense fallback={null}>
                                <AppRootAuthorizedRoute appName="content-library">
                                    <ContentLibraryModule />
                                </AppRootAuthorizedRoute>
                            </Suspense>
                        ),
                    } as AppRouteObject,
                    {
                        path: '/user-management/*',
                        breadcrumb: {
                            name: 'User Management',
                        },
                        element: (
                            <Suspense fallback={null}>
                                <AppRootAuthorizedRoute appName="user-management">
                                    <UserManagementModule />
                                </AppRootAuthorizedRoute>
                            </Suspense>
                        ),
                    } as AppRouteObject,
                    {
                        path: '/notfound',
                        element: <NotFound />,
                    },
                    {
                        path: '/unauthorized',
                        element: <NotFound />,
                    },
                ],
            },
            {
                path: '/signed-off',
                element: <SignedOffMessage />,
            },
            {
                path: '/timed-out',
                element: <SessionExpiredMessage />,
            },
            {
                path: '*',
                element: <NotFound />,
            },
        ],
    },
];

export default routes;
