import { TenantOidc, TenantConfiguration, toTenantOidc, toTenantConfiguration } from '@faro/study-designer-model';
import axios from 'axios';

/**
 * Implementations of this interface are responsible for fetching the
 * current tenant information.
 */
export interface TenantService {
    /**
     * Retrieve the tenant configuration.
     */
    get(): Promise<TenantOidc>;
    getConfiguration(): Promise<TenantConfiguration>;
}

export class DefaultTenantService implements TenantService {
    get(): Promise<TenantOidc> {
        return axios.get('/api/study-designer/system/tenant').then(response => toTenantOidc(response.data));
    }
    getConfiguration(): Promise<TenantConfiguration> {
        return axios
            .get('/api/study-designer/system/tenant/configuration')
            .then(response => toTenantConfiguration(response.data));
    }
}
