import React, { PropsWithChildren, useContext, useMemo } from 'react';
import { FeatureFlagService } from './FeatureFlag';
import { DefaultUserInfoService, UserInfoService } from './UserInfo';
import { DefaultTenantService, TenantService } from './Tenant';
import { SystemConfigService } from './SystemConfig';

/**
 * This context functions as our service registry, allowing consuming components to access services.
 */
export interface ServiceContextValue {
    featureFlagService: FeatureFlagService;

    systemConfigService: SystemConfigService;
    tenantService: TenantService;

    userInfoService: UserInfoService;
}

export const ServiceContext = React.createContext({} as ServiceContextValue);

export function ServiceContextProvider(props: PropsWithChildren<unknown>): React.JSX.Element {
    const value = useMemo(() => {
        const value: ServiceContextValue = {
            featureFlagService: new FeatureFlagService(),
            systemConfigService: new SystemConfigService(),
            tenantService: new DefaultTenantService(),
            userInfoService: new DefaultUserInfoService(),
        };
        return value;
    }, []);

    return <ServiceContext.Provider value={value}>{props.children}</ServiceContext.Provider>;
}

export function useServiceContext(): ServiceContextValue {
    return useContext(ServiceContext);
}
