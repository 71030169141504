import { AnalyticsProvider } from '@faro/app-context';
import { useSystemConfigQuery } from '../../query';
import { useUser } from '@faro/auth';
import { UserInfo } from '@faro/user-management-model';

interface AppAnalyticsProviderProps {
    children: React.ReactNode;
}

export const AppAnalyticsProvider: React.FC<AppAnalyticsProviderProps> = ({ children }) => {
    const user = useUser<UserInfo>();
    const { data: systemConfig } = useSystemConfigQuery();
    const hostname = window?.location.hostname ?? '';
    const analyticsConfig = systemConfig?.analyticsConfig ?? { enabled: false, amplitudeapikey: '' };

    return (
        <AnalyticsProvider
            config={analyticsConfig}
            userId={user?.id}
            tenant={hostname}
            isFaroUser={user?.email?.endsWith('@farohealth.com')}
        >
            {children}
        </AnalyticsProvider>
    );
};
