import { ScriptHTMLAttributes, useEffect, useRef, useState } from 'react';

export interface UseScriptOptions extends ScriptHTMLAttributes<any> {
    src: string;
    /**
     * @default true
     */
    enabled?: boolean;
}

export interface UseScriptResult {
    loaded: boolean;
    error?: Error;
}

const defaultAttributes: ScriptHTMLAttributes<any> = {
    type: 'text/javascript',
    async: true,
};

/**
 * Adds a script to the body with the given attributes
 * @param options The script configuration options/attributes
 */
export function useScript(options: UseScriptOptions): UseScriptResult {
    const { enabled = true, ...attributes } = options;
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState<Error | undefined>();
    const mountedRef = useRef(false);

    useEffect(() => {
        if (!enabled || mountedRef.current) {
            return;
        }

        function onLoad(): void {
            setLoaded(true);
        }

        function onError(error: ErrorEvent): void {
            console.error(`Failed to load script with src="${attributes.src}"`, error.error);
            setError(error.error);
        }

        const script = document.createElement('script');
        Object.assign(script, defaultAttributes, attributes);

        script.addEventListener('load', onLoad);
        script.addEventListener('error', onError);
        document.body.appendChild(script);

        mountedRef.current = true;

        return () => {
            script.removeEventListener('load', onLoad);
            script.removeEventListener('error', onError);
            document.body.removeChild(script);
        };
    }, [enabled]);

    return {
        loaded,
        error,
    };
}
