import React, { ComponentProps } from 'react';
import styles from './NavLayout.module.scss';
import { BookIcon, StudyDefinitionIcon, UsersIcon } from '@faro/icons';
import SideNav from '../SideNav/SideNav';
import { useAppPermissions } from '../../package/auth';
import { LinkData } from '../SideNav/SideNavHeader/SideNavHeader';
import { useUser } from '@faro/auth';

export type NavLayoutProps = ComponentProps<'div'>;

/**
 * This layout renders the Nav.
 *
 * If it is the top nav, it is across the top of the page and renders
 * children within the remaining body space.
 *
 * For studyspaces, it renders a side nav bar instead.
 *
 * @param props TopNavBar properties
 * @constructor
 */
export function NavLayout(props: NavLayoutProps): JSX.Element {
    const { className = '', children, ...rest } = props;

    const user = useUser();
    const permissions = useAppPermissions();
    const appLinks = getGrantedAppLinks(permissions.grantedApps);

    return (
        <div {...rest} className={`${styles.layoutContainer} ${className} ${styles.spaces}`}>
            <div className={styles.bodyContainer}>
                <div className={styles.body}>{children}</div>
            </div>
            <SideNav user={user} headerLinks={appLinks} />
        </div>
    );
}

function getGrantedAppLinks(grantedApps: Set<string>): LinkData[] {
    const links: LinkData[] = [];
    if (grantedApps.has('study-spaces')) {
        links.push({
            id: 'studySpaces',
            label: 'Study Spaces',
            icon: <StudyDefinitionIcon />,
            url: '/spaces',
        });
    }
    if (grantedApps.has('content-library')) {
        links.push({
            id: 'contentLibrary',
            label: 'Content Library',
            icon: <BookIcon />,
            url: '/libraries',
        });
    }
    if (grantedApps.has('user-management')) {
        links.push({
            id: 'userManagement',
            label: 'User Management',
            icon: <UsersIcon />,
            url: '/user-management',
        });
    }
    return links;
}
