import React, { PropsWithChildren, useMemo } from 'react';
import { FaroButton, FaroFlagMessage, FaroIconButton, useToastService } from '@faro/design-system';
import { errorToast } from '@faro/app-context';
import { AppContext, AppContextValue } from '@faro/app-context';
import { RefreshIcon, XIcon } from '@faro/icons';

export function AppContextProvider(props: PropsWithChildren<unknown>): React.JSX.Element {
    const toastService = useToastService();

    const value = useMemo(() => {
        function showErrorNotification(message: string, error?: any): void {

            const changeEventTimeoutCode =  'change_event_listener.timeout';
            if ('code' in error && error.code === changeEventTimeoutCode) {
                toastService.error(props => {
                    return (
                        <FaroFlagMessage type={props.type}>
                            <FaroFlagMessage.Header
                                controls={
                                    <FaroIconButton
                                        size={'small'}
                                        appearance="inline"
                                        icon={<XIcon />}
                                        onClick={() => {
                                            props.close();
                                        }}
                                    />
                                }
                            >
                                Server communication issue
                            </FaroFlagMessage.Header>
                            <FaroFlagMessage.Body>
                                Please refresh the browser page to continue.
                            </FaroFlagMessage.Body>
                            <FaroFlagMessage.Footer controls={(
                                <FaroButton size="small" icon={<RefreshIcon/>} onClick={() => { window.location.reload(); }}>Refresh</FaroButton>
                            )}/>
                        </FaroFlagMessage>
                    )
                }, {
                    toastId: changeEventTimeoutCode,
                    autoClose: false,
                    closeOnClick: false,
                    closeButton: false,
                    draggable: false,
                });
            } else {
                toastService.error(errorToast(message, error));
            }
        }

        function showErrorHandler(message: string): (error: any) => Promise<never> {
            return error => {
                showErrorNotification(message, error);
                return Promise.reject(error);
            };
        }

        const value: AppContextValue = {
            showErrorHandler,
            showErrorNotification,
        };
        return value;
    }, []);

    return <AppContext.Provider value={value}>{props.children}</AppContext.Provider>;
}
