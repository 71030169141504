import React from 'react';
import { FaroButton, FaroSystemMessage } from '@faro/design-system';
import { useNavigate } from 'react-router-dom';

/**
 * Displayed when the user has successfully logged out.
 */
function SignedOffMessage(): JSX.Element {
    const navigate = useNavigate();

    async function onSignInButtonClick(): Promise<void> {
        navigate('/');
    }

    return (
        <FaroSystemMessage
            message="You have successfully signed off."
            controls={
                <FaroButton color="primary" onClick={onSignInButtonClick}>
                    Sign in
                </FaroButton>
            }
        />
    );
}

export default SignedOffMessage;
