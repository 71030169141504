import React, { FC } from 'react';
import styles from './AuthenticationSpinner.module.scss';
import { FaroSpinner } from '@faro/design-system';

/**
 * This component displays an authentication waiting message to the user during
 * initial authentication with the application.
 */
const AuthenticationSpinner: FC = () => {
    return (
        <div className={styles.wrapper}>
            <div>
                <FaroSpinner className={styles.authenticationSpinner} />
                Authenticating
            </div>
        </div>
    );
};

export default AuthenticationSpinner;
