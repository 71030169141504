import { SystemConfig, toSystemConfig } from '@faro/study-designer-model';
import axios from 'axios';

export const systemConfigClient = {
    get: (): Promise<SystemConfig> =>
        axios.get(`/api/study-designer/system-config`).then(response => {
            // toSystemConfig has been modified to map _some_ data from the importexport section of the server's AppConfig.
            // This was done to prevent sensitive data from being included as part of the response payload.  By the time
            // we get here, the system configuration contained in response.data is already formatted/mapped to a
            // SystemConfig object.
            //
            // Since toSystemConfig is being called here in the client, the input needs to be "unmapped" to what
            // toSystemConfig expects.  Specifically, toSystemConfig expects the import/export configuration to be stored in
            // an `importexport` property.  If the `importexport` property doesn't exist on the input passed into the
            // toSystemConfig call, it will fail (because the toImportExportVersionConfig mapper will not be able to find
            // the `formatversion` property it expects).
            return toSystemConfig({ ...response.data, importexport: response.data.importExportVersionConfig });
        }),
};
