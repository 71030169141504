import { UserInfo } from '@faro/user-management-model';
import type { AppName, AppPermissions } from './AppPermissions';

const appNameByRole = new Map<string, AppName[]>([
    ['EDITOR', ['study-designer', 'study-spaces']],
    ['VIEWER', ['study-designer', 'study-spaces']],
    ['DYNAMIC-FIELD-DEF-ADMIN', ['content-library']],
    ['SNIPPET-ADMIN', ['content-library']],
    ['USER-ADMIN', ['user-management']],
]);

export function getAppPermissions(user: UserInfo): AppPermissions {
    const grantedApps = (user.roles || []).reduce((grantedApps: Set<AppName>, role: string) => {
        const appName = appNameByRole.get(role);
        appName?.forEach(permission => {
            grantedApps.add(permission);
        });
        return grantedApps;
    }, new Set<AppName>([]));

    return { grantedApps };
}
