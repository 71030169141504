import { useMemo } from 'react';
import { useUser } from '@faro/auth';
import type { AppName, AppPermissions } from './AppPermissions';
import { getAppPermissions } from './getAppPermissions';
import { isEmpty } from 'lodash';

/**
 * Use this hook to retrieve the permissions for the current user.
 */
export function useAppPermissions(): AppPermissions {
    const user = useUser();
    return useMemo(() => {
        return !isEmpty(user?.roles) ? getAppPermissions(user) : emptyPermissions;
    }, [user?.roles?.join('')]);
}

const emptyPermissions = { grantedApps: new Set<AppName>() };
