import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

/**
 * Component that scrolls the page to the top when the router location changes
 */
function ScrollToTop(): null {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
}

export default ScrollToTop;
