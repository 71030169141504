import React from 'react';
import { useNavigate, NavigateFunction } from 'react-router-dom';
import { FaroButton, FaroSystemMessage } from '@faro/design-system';

/**
 * Displayed when the users session expires.
 */
function SessionExpiredMessage(): JSX.Element {
    const navigate: NavigateFunction = useNavigate();

    async function onSignInButtonClick(): Promise<void> {
        const lastPathname = sessionStorage.getItem('last_pathname');

        if (lastPathname) {
            navigate(lastPathname);
        } else {
            navigate('/');
        }
    }

    return (
        <FaroSystemMessage
            message="Your session has expired."
            controls={
                <FaroButton color="primary" onClick={onSignInButtonClick}>
                    Sign in
                </FaroButton>
            }
        />
    );
}

export default SessionExpiredMessage;
