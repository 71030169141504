import React from 'react';
import { useAppPermissions } from '../package/auth';
import { Navigate } from 'react-router-dom';
import { NotFound } from './NotFound/NotFound';

function Home(): React.JSX.Element {
    const { grantedApps } = useAppPermissions();
    const primaryAppRoute = getPrimaryAppRoute(grantedApps);

    if (primaryAppRoute == null) {
        return <NotFound />;
    }

    return <Navigate replace to={primaryAppRoute} />;
}

function getPrimaryAppRoute(grantedApps: Set<string>): string | undefined {
    return grantedApps.has('study-spaces')
        ? '/spaces'
        : grantedApps.has('content-library')
          ? '/libraries'
          : grantedApps.has('user-management')
            ? '/user-management'
            : undefined;
}

export default Home;
