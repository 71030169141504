import React, { ForwardedRef, forwardRef, useEffect } from 'react';
import { FaroAlert, FaroAlertProps } from '@faro/design-system';
import { formatError } from '@faro/app-context';

export interface ErrorMessageProps extends Omit<FaroAlertProps, 'type'> {
    message: string;
    error: unknown;
}

function ErrorMessage(props: ErrorMessageProps, ref: ForwardedRef<HTMLDivElement>): JSX.Element {
    const { message, error, className = '', ...rest } = props;

    useEffect(() => {
        // Captures error for app-insights
        console.error(message, error);
    }, [error]);

    const formattedError = formatError(error);

    return (
        <FaroAlert
            {...rest}
            ref={ref}
            className={`
                ${className}
            `}
            type="error"
        >
            <FaroAlert.Header>{message}</FaroAlert.Header>
            {formattedError && <FaroAlert.Body>{formattedError}</FaroAlert.Body>}
        </FaroAlert>
    );
}

export default forwardRef(ErrorMessage);
